import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  StyleRules,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Avatar, Image } from 'antd';
import imageCompression from 'browser-image-compression';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import {
  addCompany,
  addVerification,
  getCompanyGetVerification,
  updateVerification,
} from '../../actions/companyActions';
import logo from '../../images/fintegrity-logo.jpeg';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import debug from '../../utils/debug';
import { createCompanyBasicInfo, createCompanySupplementInfo } from './company.model';
import SuccessPrompt from './SuccessPromptView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    AvatarBorder: {
      marginBottom: '1rem',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    formContainer: {
      margin: '0 auto',
      width: '60%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    stepperContainer: {
      margin: '0 auto',
    },
    stepper: {
      backgroundColor: 'transparent',
    },
    gridItem: {
      paddingTop: theme.spacing(1),
      '& img': {
        maxHeight: 360,
      },
    },
    attachment: {
      minHeight: theme.spacing(10),
    },
    submit: {
      margin: '20px',
    },
    linkSteps: {
      color: 'black',
      textDecoration: 'none',
    },
    inputItem: {
      margin: '20px 0',
    },
    tipFont: {
      color: 'rgba(128, 128, 128, 0.5)',
      textAlign: 'center',
      lineHeight: '40px',
    },
    avatarColor: {
      textAlign: 'center',
      width: '100%',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    contentCenter: {
      margin: '0 auto',
    },
  });

const VerificationView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const company = useSelector((appState: AppState) => appState.company);
  const verificationId = company?.verification?.id;
  const [titleInfo, setTitleInfo] = useState<string>('');
  const [descriptionInfo, setDescriptionInfo] = useState<string>('');

  const [activeStep, setActiveStep] = useState(0);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  // const [status, setStatus] = useState<JSX.Element>(<></>);
  const [successPromptDisabled, setSuccessPromptDisabled] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const handleChangeSetChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    // setIsDialogUserAgreement(event.target.checked);
    // setIsDialogOpenPrivacyAgreement(event.target.checked);
  };

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      debug(error as string);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleVerificationAdd = async (): Promise<void> => {
    if (!isBasicInfoValid) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '基本信息验证错误',
        })
      );
      return;
    }
    triggerSupplementInfo('CompanySupplementInfo');
    if (!isSupplementInfoValid) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '其他信息验证错误',
        })
      );
      return;
    }
    if (!checked) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '请确认阅读完成注册协议',
        })
      );
      return;
    }
    const result = await dispatch(addCompany());
    if (result) {
      try {
        const data = {
          id: verificationId,
          basicInfo: getBasicInfoValues('CompanyBasicInfo'),
          supplementInfo: getSupplementInfoValues('CompanySupplementInfo'),
          status: 'SUBMITTED',
        };
        await dispatch(addVerification(data));
        setActiveStep(1);
        setTitleInfo('你已注册企业用户!');
        setDescriptionInfo('提交信息成功, 您可以尝试发布项目。');
        setSuccessPromptDisabled(true);
      } catch (error) {
        console.log('提交错误', error);
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: '注册失败',
          })
        );
      }
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '注册失败',
        })
      );
    }
  };

  const handleEditVerificationUpdate = async () => {
    triggerSupplementInfo('CompanySupplementInfo');
    if (!isSupplementInfoValid) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '其他信息验证错误',
        })
      );
      return;
    }
    try {
      const data = {
        id: verificationId,
        basicInfo: getBasicInfoValues('CompanyBasicInfo'),
        supplementInfo: getSupplementInfoValues('CompanySupplementInfo'),
        status: 'SUBMITTED',
      };

      await dispatch(updateVerification(data));
      setActiveStep(1);
      setTitleInfo('你已修改企业信息!');
      setDescriptionInfo('修改信息成功, 您可以尝试发布项目。');
      setSuccessPromptDisabled(true);
    } catch (error) {
      console.log('提交错误');
    }
  };

  const {
    setValue: setSupplementInfoValue,
    control: controlSupplementInfo,
    getValues: getSupplementInfoValues,
    trigger: triggerSupplementInfo,
    // reset: restSupplementInfo,
  } = useForm({
    defaultValues: {
      CompanySupplementInfo: createCompanySupplementInfo(),
    },
    mode: 'onChange',
  });

  const {
    // isDirty: isSupplementInfoDirty,
    errors: errorsSupplementInfo,
    isValid: isSupplementInfoValid,
  } = useFormState({
    control: controlSupplementInfo,
  });
  const { fields: certificateFileList, append, remove } = useFieldArray({
    control: controlSupplementInfo,
    name: 'CompanySupplementInfo.certificateFileList',
  });

  const {
    setValue: setBasicInfoValue,
    control: controlBasicInfo,
    getValues: getBasicInfoValues,
    // reset: restBasicInfo,
  } = useForm({
    defaultValues: {
      CompanyBasicInfo: createCompanyBasicInfo(),
    },
    mode: 'onChange',
  });

  const {
    // isDirty: isBasicInfoDirty,
    errors: errorsBasicInfo,
    isValid: isBasicInfoValid,
  } = useFormState({
    control: controlBasicInfo,
  });
  const [avatarName, setAvatarName] = useState('');
  const [avatarImg, setAvatarImg] = useState('');
  const [isAvatarUploadDialogOpen, setIsAvatarUploadDialogOpen] = useState<boolean>(false);

  const AvatarHandle = (): void => {
    setAvatarName('Avatar');
    setIsAvatarUploadDialogOpen(true);
  };

  const handleOnSaveFile = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    if (avatarName == 'Avatar') {
      setIsAvatarUploadDialogOpen(false);
      if (fileObjects.length == 1) {
        const AvatarData = await compressFile(fileObjects[0].file);
        setAvatarImg(AvatarData);
        setBasicInfoValue('CompanyBasicInfo.avatar', AvatarData);
        setAvatarName('');
        return;
      } else {
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: '上传企业头像错误，请上传一张图片',
          })
        );
        return;
      }
    }
    debug((fileObjects as unknown) as string);
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await compressFile(element);
      append({ file: fileToUpload });
    }
  };
  const verificationCompanyOtherInfoView = (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              minRows={4}
              label="企业业务介绍"
              error={!!errorsSupplementInfo.CompanySupplementInfo?.businessDescription}
              helperText={
                !!errorsSupplementInfo.CompanySupplementInfo?.businessDescription &&
                '请输入企业业务介绍'
              }
            />
          )}
          control={controlSupplementInfo}
          name="CompanySupplementInfo.businessDescription"
          rules={{ required: true }}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="公司网站"
              size="small"
            />
          )}
          control={controlSupplementInfo}
          name="CompanySupplementInfo.enterpriseWebsite"
        ></Controller>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" align="left" color="secondary" className={classes.attachment}>
          社会团体上传登记证书，企业上传营业执照，若办理过三证合一的企业，请上传最新的营业执照。格式要求：原件照片／扫描件或复印件加盖企业公章后的扫描件（支持jpg、jpeg、bmp、png、PDF,格式的照片，大小不超过2M）
        </Typography>
        <Grid container>
          <Button
            type="button"
            fullWidth
            size="small"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e): void => {
              e.preventDefault();
              setIsUploadDialogOpen(true);
            }}
          >
            上传
          </Button>
          <DropzoneDialogBase
            fileObjects={[]}
            open={isUploadDialogOpen}
            onAdd={(fileObjects): void => {
              handleOnSaveFile(fileObjects);
            }}
            acceptedFiles={['.jpg,.jpeg,.bmp,.png,.pdf']}
            showPreviews={true}
            maxFileSize={50000000}
            dropzoneText="点击或拖拽文件到此处"
            dialogTitle="上传附件"
            submitButtonText="上传"
            cancelButtonText="取消"
            onClose={(): void => {
              setIsUploadDialogOpen(false);
            }}
          />
          {certificateFileList.map((file, indexImg) => {
            return (
              <Grid key={file.id} item xs={12}>
                <Controller
                  render={({ field: { value } }) => (
                    <>
                      <Image src={value.file} width={300} />
                      <br></br>
                      <Button
                        className={classes.submit}
                        type="button"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (value.id) {
                            // setIsDialogOpen(true);
                            // setDeleteId(indexImg);
                          } else {
                            remove(indexImg);
                          }
                        }}
                      >
                        删除
                      </Button>
                    </>
                  )}
                  name={`CompanySupplementInfo.certificateFileList.${indexImg}`}
                  control={controlSupplementInfo}
                ></Controller>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        item
        alignItems="center"
        xs={12}
      >
        {company.verification?.status == 'NONE' || !company.verification?.status ? (
          <Typography variant="subtitle2" align="center">
            <Checkbox color="primary" checked={checked} onChange={handleChangeSetChecked} />
            请确认阅读完成 &nbsp;
            <span>
              <a
                href="https://www.fintegrity.cn/user"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                注册协议
              </a>
            </span>
          </Typography>
        ) : (
          ''
        )}
      </Grid>
      <Grid
        xs={12}
        container
        direction="row"
        justifyContent="space-around"
        item
        alignItems="center"
      >
        {company.verification?.status == 'NONE' || !company.verification?.status ? (
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            className={classes.submit}
            // disabled={
            //   buttonDisabled
            // }
            onClick={(e): void => {
              e.preventDefault();
              handleVerificationAdd();
            }}
          >
            同意并注册
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            // disabled={
            //   buttonDisabled
            // }
            onClick={(e): void => {
              e.preventDefault();
              handleEditVerificationUpdate();
            }}
            size="small"
          >
            修改信息
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const verificationCompanyBasicInfoView = (
    <Grid container spacing={2}>
      <div className={classes.contentCenter}>
        <Controller
          render={({ field: { value } }) => (
            <Avatar
              onClick={AvatarHandle}
              className={classes.AvatarBorder}
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }}
              src={value ? value : logo}
            />
          )}
          name="CompanyBasicInfo.avatar"
          control={controlBasicInfo}
        ></Controller>

        <DropzoneDialogBase
          fileObjects={[]}
          open={isAvatarUploadDialogOpen}
          onAdd={(fileObjects): void => {
            handleOnSaveFile(fileObjects);
          }}
          acceptedFiles={['.jpg,.jpeg,.bmp,.png']}
          showPreviews={true}
          filesLimit={1}
          maxFileSize={2097152}
          dropzoneText="点击此处或拖拽文件到此处"
          dialogTitle="上传企业logo"
          submitButtonText="上传"
          cancelButtonText="取消"
          onClose={(): void => {
            setIsAvatarUploadDialogOpen(false);
          }}
        />
      </div>
      <div className={classes.avatarColor}>
        {avatarImg != '' ? (
          <Typography variant="caption" display="block" gutterBottom>
            点击图片编辑 企业logo
          </Typography>
        ) : (
          <Typography variant="caption" display="block" gutterBottom>
            点击图片上传 企业logo
          </Typography>
        )}
      </div>
      <Grid item container xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              label="企业/组织全称"
              error={!!errorsBasicInfo.CompanyBasicInfo?.registrationName}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.registrationName && '请输入企业名称'}
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.registrationName"
          rules={{ required: true }}
        ></Controller>

        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              {...field}
              label="企业简称"
              error={!!errorsBasicInfo.CompanyBasicInfo?.alias}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.alias && '请输入企业简称'}
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.alias"
          rules={{ required: true }}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              required
              size="small"
              variant="outlined"
              margin="normal"
              fullWidth
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              label="企业/组织注册编号"
              error={!!errorsBasicInfo.CompanyBasicInfo?.registrationId}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.registrationId && '请输入企业注册编号'
              }
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.registrationId"
          rules={{ required: true }}
        ></Controller>

        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.companyIndustry"
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              error={!!errorsBasicInfo.CompanyBasicInfo?.companyIndustry}
            >
              <InputLabel htmlFor="label-gender">企业行业</InputLabel>
              <Select
                labelId="label-EnterpriseSize"
                id="EnterpriseSize"
                label="企业行业"
                {...field}
                // readOnly={summaryMode || verifyDisabled}
                // disabled={summaryMode || verifyDisabled}
              >
                <MenuItem value={''} disabled>
                  -请选择-
                </MenuItem>
                <MenuItem value={'service'}>服务业</MenuItem>
                <MenuItem value={'manufacture'}>制造业</MenuItem>
                <MenuItem value={'construction'}>建筑业</MenuItem>
                <MenuItem value={'farming'}>农业</MenuItem>
                <MenuItem value={'IT'}>信息技术业</MenuItem>
                <MenuItem value={'finance'}>金融业</MenuItem>
                <MenuItem value={'traffic'}>交通运输业</MenuItem>
              </Select>
              {!!errorsBasicInfo.CompanyBasicInfo?.companyIndustry && (
                <FormHelperText>请选择公司行业</FormHelperText>
              )}
            </FormControl>
          )}
        ></Controller>

        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.enterpriseSize"
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              required
              error={!!errorsBasicInfo.CompanyBasicInfo?.enterpriseSize}
            >
              <InputLabel htmlFor="label-gender">企业规模</InputLabel>
              <Select
                labelId="label-EnterpriseSize"
                id="EnterpriseSize"
                label="企业规模"
                {...field}
                // readOnly={summaryMode || verifyDisabled}
                // disabled={summaryMode || verifyDisabled}
              >
                <MenuItem value={''} disabled>
                  -请选择-
                </MenuItem>
                <MenuItem value={'mini'}>小微企业 20人以下</MenuItem>
                <MenuItem value={'small'}>中小型企业 20-300人</MenuItem>
                <MenuItem value={'medium'}>中大型企业 300-1000人</MenuItem>
                <MenuItem value={'big'}>大型企业 1000+</MenuItem>
              </Select>
              {!!errorsBasicInfo.CompanyBasicInfo?.enterpriseSize && (
                <FormHelperText>请选择公司规模</FormHelperText>
              )}
            </FormControl>
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.enterpriseLegalPerson"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              {...field}
              label="法人(或运营者)姓名"
              error={!!errorsBasicInfo.CompanyBasicInfo?.enterpriseLegalPerson}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.enterpriseLegalPerson &&
                '请输入法人(或运营者)姓名'
              }
            />
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.registrationAddress"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              required
              {...field}
              label="经营地址"
              error={!!errorsBasicInfo.CompanyBasicInfo?.registrationAddress}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.registrationAddress && '请输入经营地址'
              }
            />
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactAddress"
          rules={{ required: true }}
          size="small"
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              {...field}
              label="联系地址"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactAddress}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.contactAddress && '请输入联系地址'}
            />
          )}
        ></Controller>
        <Controller
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactPhone"
          rules={{
            required: true,
            pattern: {
              value: /^1[3-9]\d{9}$/,
              message: '请输入正确的手机号',
            },
          }}
          render={({ field }) => (
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              {...field}
              label="联系电话"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactPhone}
              helperText={
                !!errorsBasicInfo.CompanyBasicInfo?.contactPhone &&
                `${errorsBasicInfo.CompanyBasicInfo?.contactPhone.message}`
              }
            />
          )}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={
                company?.verification?.status == 'SUBMITTED' ||
                company?.verification?.status == 'VERIFIED'
                  ? true
                  : false
              }
              label="联系邮箱"
              error={!!errorsBasicInfo.CompanyBasicInfo?.contactEmail}
              helperText={!!errorsBasicInfo.CompanyBasicInfo?.contactEmail && '请输入联系邮箱'}
            />
          )}
          control={controlBasicInfo}
          name="CompanyBasicInfo.contactEmail"
          rules={{ required: true }}
        ></Controller>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      ></Grid>
    </Grid>
  );

  useEffect(() => {
    if (company.id && company.status) dispatch(getCompanyGetVerification(company.id));
  }, [company?.id]);

  useEffect(() => {
    if (company.verification?.basicInfo) {
      setBasicInfoValue('CompanyBasicInfo', company.verification?.basicInfo);
    }
    if (company.verification?.supplementInfo) {
      setSupplementInfoValue('CompanySupplementInfo', company.verification.supplementInfo);
    }
    if (company.verification?.supplementInfo?.certificateFileList) {
      setSupplementInfoValue(
        'CompanySupplementInfo.certificateFileList',
        company.verification?.supplementInfo?.certificateFileList
      );
    }
  }, [company.verification?.basicInfo, company.verification?.supplementInfo]);

  return (
    <Container className={classes.root} component="main" maxWidth="lg">
      <Helmet title="企业注册信息"></Helmet>
      {!successPromptDisabled && (
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.formContainer}>
              {activeStep == 0 && verificationCompanyBasicInfoView}
              {activeStep == 0 && verificationCompanyOtherInfoView}
            </Paper>
            <p className={classes.tipFont}>*为必填，基本信息必填、基本信息提交后不能更改</p>
          </Grid>
        </Grid>
      )}
      {successPromptDisabled && <SuccessPrompt title={titleInfo} description={descriptionInfo} />}
    </Container>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  inputEmail?: string | undefined;
  inputSecret?: string | undefined;
  inputAuth?: string | undefined;
}

export default withStyles(styles)(VerificationView);
