import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createStyles,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { navigate } from 'gatsby';
import i18n from 'i18next';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    infoTitle: {
      margin: theme.spacing(3),
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  });
const SuccessPrompt = ({ classes, title, description }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('SuccessPrompt');
  const [count, setCount] = useState(15);

  const transform = () => {
    navigate('/company');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        transform();
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <>
      <Paper className={classes.formContainer}>
        <div className={classes.infoTitle}>
          <Alert severity="success" variant="filled">
            <AlertTitle>
              <strong>{title}</strong>
            </AlertTitle>
            {description}
          </Alert>
        </div>
        <div className={classes.bottomButton}>
          <Typography>
            {t('success_prompt:countdown')}
            {count} {t('success_prompt:return')}
          </Typography>
          <br></br>
          <br></br>
          <Button variant="contained" color="primary" onClick={transform}>
            {t('success_prompt:homepage')}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  title?: string;
  description?: string;
}

export default withStyles(styles)(SuccessPrompt);
